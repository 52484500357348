<script>
import { userMixins } from "../../../mixins/user.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import Pagination from "../../common/Pagination.vue";

const KB = 1024;
const MB = Math.pow(1024, 4);
const GB = Math.pow(1024, 4);
const TB = Math.pow(1024, 4);

export default {
  components: {Popup, Pagination},
  mixins: [userMixins, notificationMixins],
  data() {
    return {
      prefix: this.$route.query.prefix,
      base: this.$route.query.base,
      suffix: this.$route.query.suffix,
      number: this.$route.query.number,
      resultsPerPage: parseInt(this.$route.query.resultsPerPage),
      page: parseInt(this.$route.query.page),
      totalItemCount: 0,
      items: [],
      popupEnabled: false,
      selectedItem: null,
      searchComplete: false,
    };
  },
  asyncComputed: {
    async doSearch() {
      let q = {pageSize: this.resultsPerPage, page: this.page}
      if (this.number) {
        q.number = this.number
      } else {
        q.prefix = this.prefix;
        q.base = this.base;
        q.suffix = this.suffix;
      }
      this.searchComplete = false;
      return await this.callAPI("reds/search", {
        showLoader: false,
        method: "GET",
        query: q,
      }).then(response => {
        this.totalItemCount = response.totalItemCount;
        this.items = response.items;
        this.searchComplete = true;
      });
    }
  },
  computed: {
    pages() {
      if (!this.totalItemCount) {
        return 0;
      }
      return Math.ceil(this.totalItemCount / this.resultsPerPage);
    },
  },
  methods: {
    setPage(p) {
      this.page = p;
      this.$router.replace({query: Object.assign({ ...this.$route.query }, { page: p }),}, () => {});
    },
    setPageSize(newPageSize) {
      this.resultsPerPage = newPageSize;
    },
    formatQuery() {
      if (this.number) {
        return this.number;
      } else {
        return this.prefix + "-" + this.base + "-" + this.suffix
      }
    },
    formatSize(size) {
      if (size > TB) {
        return (size / TB).toFixed(2) + " TiB"
      } else if (size > GB) {
        return (size / GB).toFixed(2) + " GiB"
      } else if (size > MB) {
        return (size / MB).toFixed(2) + " MiB"
      } else if (size > KB) {
        return (size / KB).toFixed(2) + " KiB"
      } else {
        return size + " B"
      }
    },
    showPopup(item) {
      this.selectedItem = item;
      this.popupEnabled = true;
    },
    closePopup() {
      this.selectedItem = null;
      this.popupEnabled = false;
    },
    hasRecords() {
      return this.items && this.items.length;
    }
  }
};
</script>

<template>
  <div v-if="searchComplete && !hasRecords()" class="reds-results">
    No results found matching the selected criteria
  </div>
  <div v-else class="reds-results">
    <div class="query-header">Search results include all files matching selected criteria:- {{ formatQuery() }}</div>
    <div class="reds-search-results">
      <div class="header">
        <span class="properties-header">&nbsp;</span>
        <span class="drawing-number-header">Drawing Number</span>
        <span class="issue-header">Issue</span>
        <span class="version-header">Version</span>
        <span class="sheet-num-header">Sheet No.</span>
        <span class="frame-num-header">Frame No.</span>
        <span class="filename-header">Filename (Click to view drawing)</span>
        <span class="size-header">Size</span>
        <span class="date-released-header">Date Released</span>
      </div>
      <div class="search-result-item" v-for="item in this.items" v-bind:key="item.item.id">
        <span class="properties"><button v-on:click="showPopup(item)">Properties</button></span>
        <span class="drawing-number">{{item.drawingNumber}}</span>
        <span class="issue">{{item.issue}}</span>
        <span class="version">{{item.version}}</span>
        <span class="sheet-num">{{item.sheetNumber}}</span>
        <span class="frame-num">{{item.frameNumber}}</span>
        <span class="filename"><a target="_blank" :href="'/view/' + item.item.id">{{item.item.name}}</a> </span>
        <span class="size">{{formatSize(item.item.size)}}</span>
        <span class="date-released">{{item.dateReleased}}</span>
      </div>
    </div>
    <Pagination v-if="hasRecords()" 
      :pageSize="resultsPerPage" 
      :page="page" 
      :pages="pages" 
      :totalRecords="totalItemCount" 
      @pageChange="setPage" 
      @pageSizeChange="setPageSize" />
    <Popup :valid="true" v-if="popupEnabled" @cancelled="closePopup()" @confirmed="closePopup()" :default-buttons="false">
      <template v-slot:header>{{ $t("reds-properties-popup-header") }}</template>
      <template v-slot:body>
        <div class="property-line" :key="line" v-for="line in selectedItem.metadata.split('\n')">{{ line }}</div>
      </template>
      <template v-slot:footer>
        <button v-on:click="closePopup">Close</button>
      </template>
    </Popup>
  </div>
</template>

<style lang="scss" scoped>
.reds-results {
  .property-line {
    font-family: monospace;
    font-size: large;
  }
  button {
    background: transparent;
    border: 1px solid #1275CC;
    color: #1275CC;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    margin-left: 0.3rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1.6rem;

    &:disabled {
      cursor: default;
      opacity: 0.2;
    }

    &.primary {
      background: #1275CC;
      border-color: transparent;
      color: white;
    }

    > i {
      vertical-align: -0.35rem;
      padding-right: 0.25rem;
    }
  }
  a {
    text-decoration: underline;
  }
  a:visited {
    color: rebeccapurple;
  }
  a:link {
    color: blue;
  }

  .query-header {
    text-align: center;
    background-color: mediumpurple;
    font-weight: bold;
  }
  .reds-search-results {
    display: grid;
    gap: 1rem 1rem;
    align-items: baseline;
    margin-top: 0.5rem;
    grid-template-columns: 90px 1fr 65px 80px 80px 80px 2fr 90px 120px;
    grid-auto-flow: row;
    .header {
      span {
        font-weight: bold;
      }
      display: contents;
    }
    .search-result-item {
      display: contents;
    }
  }
}
</style>
