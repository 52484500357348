<script>
import RedsSearch from "./RedsSearch.vue";
import { mapState } from "vuex";

export default {
  components: {
    RedsSearch,
  },
  computed: {
    ...mapState({
      permitted: (state) => {
        let ud = state.auth.userData;
        return (ud?.roles.includes('REDS') || ud?.isAdmin) ?? false
      },
    }),
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'reds-search' }, label: this.$t('reds-search') }]);
  },
};
</script>

<template>
  <RedsSearch v-if="permitted" />
  <p v-else>You do not have permission to access REDS.</p>
</template>
