import {apiMixins} from "./api";

const REPOSITORY_ENDPOINT = "item/repository";

export const repositoryMixins = {
  mixins: [apiMixins],
  methods: {
    async getRepositories({ page = 0, pageSize = 0, sort, showLoader = true, filter = "" } = {}) {
      const data = await this.callAPI(REPOSITORY_ENDPOINT, {
        method: "GET",
        query: { page, pageSize , sort, filter },
        showLoader
      });
      return data;
    },
    async getAllRepositories({ page = 0, pageSize = 0, sort, showLoader = true, filter = "" } = {}) {
      return await this.callAPI(REPOSITORY_ENDPOINT + "/all", {
        method: "GET",
        query: {page, pageSize, sort, filter },
        showLoader
      });
    },
    async getRepositoryByName({ showLoader = false, name = "" } = {}) {
      const data = await this.callAPI(REPOSITORY_ENDPOINT + "/by-name", {
        method: "GET",
        query: { name },
        showLoader
      });
      return data.id;
    },
    async createRepository(name, description) {
      const data = await this.callAPI(REPOSITORY_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, description }),
      });
      return data.id;
    },
  },
};
