<script>
import { repositoryMixins } from "../../../mixins/repository.js";
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
  mixins: [repositoryMixins],
  data() {
    return {
      prefix: "",
      base: "",
      suffix: "",
      number: "",
      resultsPerPage: 25,
    };
  },
  methods: {
    search() {
      let q = {resultsPerPage: this.resultsPerPage, page: "0"};
      if (this.prefix || this.base || this.suffix) {
        q.prefix = this.prefix.toUpperCase();
        q.base = this.base.toUpperCase();
        q.suffix = this.suffix.toUpperCase();
      } else if (this.number) {
        q.number = this.number.toUpperCase();
      } else {
        return;
      }
      this.$router.push({name: 'reds-results', query: q})
    },
    browse() {
      this.$router.push({name: 'find-repository-by-name', query: {name: "Jaguar/Land Rover Released Drawings"}})
          // Suppress the console "error" per https://github.com/vuejs/vue-router/issues/2932
          .catch((e) => {
            if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
              Promise.reject(e)
            }
          });
    }
  }
};
</script>

<template>
  <div class="reds-search">
    <div class="vis-mockup-reminder">{{ $t("reds-vis-mockup-reminder") }}</div>
    <hr />
    <div class="search-input">
      <span class="desc-wers">{{ $t("reds-wers-number") }}</span>
      <div class="prefix">
        <label class="prefix-label" for="prefix">{{ $t("reds-prefix") }}</label>
        <input class="uppercase-input" v-model="prefix" :placeholder="'Prefix'" v-on:keyup.enter="search" id="prefix" type="text">
      </div>
      <div class="base">
        <label class="base-label" for="base">- {{ $t("reds-base") }} -</label>
        <input class="uppercase-input" v-model="base" :placeholder="'Base'" v-on:keyup.enter="search" id="base" type="text">
      </div>
      <div class="suffix">
        <label class="suffix-label" for="suffix">{{ $t("reds-suffix") }}</label>
        <input class="uppercase-input" v-model="suffix" :placeholder="'Suffix'" v-on:keyup.enter="search" id="suffix" type="text">
      </div>
      <button class="search-wers" v-on:click="search">{{ $t("reds-search-button") }}</button>
      <button class="browse-wers" v-on:click="browse">{{ $t("reds-browse-button") }}</button>
      <label for="number" class="desc-number">{{ $t("reds-numbers") }}</label>
      <input v-model="number" :placeholder="'Number'" v-on:keyup.enter="search" class="number uppercase-input" id="number" type="text">
      <span class="types">{{ $t("reds-numbers-types") }}</span>
      <button class="search-number" v-on:click="search">{{ $t("reds-search-button") }}</button>
      <button class="browse-number" v-on:click="browse">{{ $t("reds-browse-button") }}</button>
      <span class="wildcard">{{ $t("reds-wildcard") }}</span>
      <label for="results-per-page" class="results-text">{{ $t("reds-results-per-page") }}</label>
      <select v-model="resultsPerPage" id="results-per-page" class="results-per-page">
        <option value="10">10</option>
        <option value="25" selected>25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reds-search {
  button {
    background: transparent;
    border: 1px solid #1275CC;
    color: #1275CC;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    margin-left: 0.3rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1.6rem;

    &:disabled {
      cursor: default;
      opacity: 0.2;
    }

    &.primary {
      background: #1275CC;
      border-color: transparent;
      color: white;
    }

    > i {
      vertical-align: -0.35rem;
      padding-right: 0.25rem;
    }
  }
  .vis-mockup-reminder {
    text-align: center;
  }
  .search-input {
    display: grid;
    gap: 1rem 1rem;
    align-items: end;
    grid:
        "desc-wers prefix base suffix search-wers browse-wers" 1fr
        "desc-number number types types search-number browse-number" 1fr
        "wildcard wildcard . . results-text results-per-page" 1fr / 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
    .desc-wers {
      grid-area: desc-wers;
      font-weight: bold;
      text-align: center;
    }
    .prefix {
      grid-area: prefix;
      display: flex;
      flex-direction: column;
      .prefix-label {
        text-align: center;
      }
    }
    .base {
      grid-area: base;
      display: flex;
      flex-direction: column;
      .base-label {
        text-align: center;
      }
    }
    .suffix {
      grid-area: suffix;
      display: flex;
      flex-direction: column;
      .suffix-label {
        text-align: center;
      }
    }
    .search-wers {
      grid-area: search-wers;
      justify-self: start;
    }
    .browse-wers {
      grid-area: browse-wers;
      justify-self: start;
    }
    .desc-number {
      grid-area: desc-number;
      font-weight: bold;
      text-align: center;
    }
    .number {
      grid-area: number;
    }
    .types {
      grid-area: types;
    }
    .search-number {
      grid-area: search-number;
      justify-self: start;
    }
    .browse-number {
      grid-area: browse-number;
      justify-self: start;
    }
    .wildcard {
      grid-area: wildcard;
      text-align: center;
    }
    .results-text {
      grid-area: results-text;
    }
    .results-per-page {
      grid-area: results-per-page;
      justify-self: start;
    }
    .uppercase-input {
        text-transform: uppercase;
    }
  }
}
</style>
